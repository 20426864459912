import React, { Suspense } from "react";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "./Loader";
import MusicLibrary from "../Components/MusicComponents/MusicLibrary";
import MusicFeed from "../Components/MusicComponents/MusicFeed";
import MusicTrendings from "../Components/MusicComponents/MusicTrendings";
import MusicFavorites from "../Components/MusicComponents/MusicFavorites";
import MusicPlayer from "../Components/MusicComponents/MusicPlayer";
import ErrorPage from "./ErrorPage";
import { Route, Routes } from "react-router-dom";

const MusicRouters = ()=>{

    return (
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
          <Routes>
          <Route exact path="/" element={<MusicLibrary />} />
          <Route path="/feed" element={<MusicFeed />} />
          <Route path="/trending" element={<MusicTrendings />} />
          <Route path="/favorite" element={<MusicFavorites />} />
          <Route path="/player" element={<MusicPlayer />} />
          {/* <Route path="/signIn" element={<SignInComp />} />
          <Route path="/signUp" element={<SignUpComp />} /> */}

          {/* <Route element={<PrivateComponent />}>
          
          </Route> */}
<Route path="/*" element={<ErrorPage /> } />
          
          </Routes>
          </Suspense>
          </ErrorBoundary>
    )
}

export default MusicRouters;