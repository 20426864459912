import React from "react";
import styled from "styled-components";
const ErrorMainDiv = styled.div`
.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
//     height: 400px;
     color: #FFFFFF ;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg text-center h2{
			 font-size:80px;
             color: #FFFFFF !important;
			 }
			 
			 .link_404{			 
	color: #FFFFFF!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-100px;
    color: #FFFFFF !important}
`
const Error =()=>{

    return (<ErrorMainDiv>
    <section className="page_404">
	<div className="container">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-sm-10 col-sm-offset-1  text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center">404</h1>
		</div>
		
		<div className="contant_box_404">
		
		
		<a href="/" className="link_404">Go to Home</a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
    </ErrorMainDiv>)
}

export default Error;