import React from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
const MusicFavorites =()=>{
    const btnMobileStyle = useMediaQuery('(max-width: 768px)')
    return(<>
    <h1>Hindi_Mp4</h1>
    <iframe width={btnMobileStyle?"300":"560"} height="315" src="https://www.youtube.com/embed/ehbbyEGwraI?si=0KHLQI4LASe2uO9Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </>
        
    )
}

export default MusicFavorites;