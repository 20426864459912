import styled from "styled-components";

export const MainHomeContainer = styled.div`

.NewsCard{
width:30rem;
    text-align: -webkit-auto;
    margin-bottom: 20px;
   @media only screen and (max-width: 600px) {width:22rem;}
}
   .NewsDCard{
width:46rem;
    text-align: -webkit-auto;
    margin-bottom: 20px;
   @media only screen and (max-width: 600px) {width:22rem;}
}
    .CardDiv{
    text-align: -webkit-center;
    margin: 15px;
    }
    .CardDeskDiv{
    margin: 50px 10px 15px 115px;
    display: inline-flex;
    .ImageSize{
    
    height: 20rem;
    
}
    }
    .BtnGrp{
font-family: monospace;    }
.NewsFeed{
display: flex;
    justify-content: center;
flex-flow: wrap;
}

`;