import React,{ Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

class ErrorBoundary extends Component
{
  constructor(){
    super();
    this.state={
      hasError:false
    }
  }
 static getDerivedStateFromError(error){
        return {hasError:true}
      }
      componentDidCatch(error,info){
        console.log(error);
        console.log(info);
      }
  render(){
    if(this.state.hasError){
    return <h1 style={{textAlign: 'center',padding: '5%' }}><Spinner animation="grow" variant="danger" />An error has occurred<Spinner animation="grow" variant="danger" /></h1>
    }
    console.log(this.props.children)
   return this.props.children;
 }
}
export default ErrorBoundary