import React from "react";
// import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import * as Icon from 'react-bootstrap-icons';
import { useMediaQuery } from "@uidotdev/usehooks";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LibraryContainer } from "./MusicComponentStyle";

const MusicLibrary =()=>{
  const btnMobileStyle = useMediaQuery('(max-width: 768px)')

    return(<LibraryContainer>
   
    {/* <Navbar className="bg-body-tertiary justify-content-center">
      
      <Form inline>
        <Row>
          <Col xs="auto">
            <Form.Control
              type="text"
              placeholder="Search"
              className=" mr-sm-2"
            />
          </Col>
          <Col xs="auto">
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Album1</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Album2
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Album3</NavDropdown.Item>
             
              <NavDropdown.Item href="#action/3.4">
               Album4
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
      </Form>
    </Navbar>
    <Stack direction="horizontal" gap={3} >
      <div className="p-2 stackBorder">First Album</div>
      <div className="p-2 stackBorder">Second Album</div>
      <div className="p-2 stackBorder">Third Album</div>
    </Stack> */}
    <>
    <a href="https://wa.me/919525621552"> <Icon.Whatsapp className='me-5' color="royalblue"
      size={96} /></a>
     <h1>Whats_App</h1>
     
     
      <iframe width={btnMobileStyle?"300":"700"} height="315" src="https://www.youtube.com/embed/_lQ7jA7pWkM?si=9GN8QJdSH2og8den" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" ></iframe>
    </>
    </LibraryContainer>
        
        
    )
}

export default MusicLibrary;