import React from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
const MusicTrendings =()=>{
    const btnMobileStyle = useMediaQuery('(max-width: 768px)')

    return(
        <>
     <h1>React_Native_Tutorial</h1>
     <iframe width={btnMobileStyle?"300":"560"} height="315" src="https://www.youtube.com/embed/KBWWxJdsFlY?si=LqA4bIbwml8xE0g4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" ></iframe>
        
    </>
    )
}

export default MusicTrendings;