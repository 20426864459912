import React from "react";
import Spinner from 'react-bootstrap/Spinner';


const Loader =()=>{

    return (<>
    <Spinner animation="grow" />
    </>)
}

export default Loader;