import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MainHomeContainer } from './MainHomeStyle';
import MusicRouters from '../Utils/MusicRouters';
import Nav from 'react-bootstrap/Nav';
import * as Icon from 'react-bootstrap-icons';
import { BrowserRouter } from 'react-router-dom';
import { useMediaQuery } from "@uidotdev/usehooks";

function BasicExample() {
  const btnMobileStyle = useMediaQuery('(max-width: 768px)')

const CardData=[
  {
    imgUrl:'https://fireimages.govindmukund.com/GateImages/crou1.JPG',
    title:'GateHave',
    desc:'Explore our extensive range of doors and locks designed to enhance security and style for any space. From residential to commercial solutions, our products combine durability with modern design.',siteUrl:'https://gmonlineone.govindmukund.com/',
    btnText:'Go To GateHave'},

  {imgUrl:'https://fireimages.govindmukund.com/TechSol/TechSolu.jpg',title:'TechSculpt',desc:'At TechSculpt, we specialize in crafting innovative software solutions tailored to meet the unique needs of businesses across various industries. Our mission is to empower organizations with technology that enhances efficiency, productivity, and growth.',siteUrl:'https://gmonlinetwo.govindmukund.com/',btnText:'Go To TechSculpt'},
  {imgUrl:'https://fireimages.govindmukund.com/TechSol/newsimg.jfif',title:'DNewsArticle',desc:'DNewsArticles for concise, engaging updates on the most important news stories of the day and articles on different burning headlines. Each edition provides a snapshot of key events, trends, and insights across politics, business, technology, and culture.',siteUrl:'http://gmonlinethree.govindmukund.com/',btnText:'Go To DNewsArticle'},
  {imgUrl:'https://fireimages.govindmukund.com/TechSol/giftS.jfif',title:'GiftHaven',desc:'Welcome to GiftHaven, where every occasion is celebrated with the perfect gift. Our curated collection features a wide variety of unique and thoughtful items designed to delight recipients of all ages.',siteUrl:'https://gmonlineone.govindmukund.com/',btnText:'Go To GiftHaven'},
]

  return (<MainHomeContainer>
   {CardData?.map((val)=>{
    return(<div className={btnMobileStyle?"CardDiv":'CardDeskDiv'} key={val.url}>
<Card className='NewsCard' >
      <Card.Img className='ImageSize' variant="top" src={val.imgUrl} />
      <Card.Body>
        <Card.Title>{val.title}</Card.Title>
        <Card.Text>
        {val.desc}
        </Card.Text>
        <Button variant="primary" href={val.siteUrl}>{val.btnText}</Button>
      </Card.Body>
    </Card>
    </div>)
   })}
    
    {/* <Card className='NewsCard'>
      <Card.Img variant="top" src="" />
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text>
        At TechSculpt, we specialize in crafting innovative software solutions tailored to meet the unique needs of businesses across various industries. Our mission is to empower organizations with technology that enhances efficiency, productivity, and growth.
        </Card.Text>
        <Button variant="primary" href='https://gmonlinetwo.govindmukund.com/'>Go To TechSculpt</Button>
      </Card.Body>
    </Card>
    <Card className='NewsCard'>
      <Card.Img variant="top" src="https://fireimages.govindmukund.com/TechSol/giftS.jfif" />
      <Card.Body>
        <Card.Title>GiftHaven</Card.Title>
        <Card.Text>
        Welcome to GiftHaven, where every occasion is celebrated with the perfect gift. Our curated collection features a wide variety of unique and thoughtful items designed to delight recipients of all ages.
        </Card.Text>
        <Button variant="primary" href='https://govindmukund.com'>Go To GiftHaven</Button>
      </Card.Body>
    </Card>*/}
    <div className="CardDiv" >
    <Card className='NewsDCard'>
      <Card.Header><Nav className="justify-content-center" activeKey="/home">
        <Nav.Item>
        <Icon.Rss color="red" />
          <Nav.Link href="/feed">Telugu_Mp4</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.Fire color="red" />
          <Nav.Link href="trending">React_Tutorial</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.CaretRightFill color="red" />
          <Nav.Link href="player">NEWS</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.HeartFill color="red" />
          <Nav.Link href="favorite">Hindi_Mp4</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Icon.FileMusicFill color="red" />
          <Nav.Link href="/">Contact</Nav.Link>
        </Nav.Item>
      </Nav></Card.Header>
      <Card.Body>
        <BrowserRouter>
        <MusicRouters />
        </BrowserRouter>
      
      </Card.Body>
      <Card.Footer className="text-muted">Entertainment</Card.Footer>
    </Card> 
    </div>
    </MainHomeContainer>
    
  );
}

export default BasicExample;